import React, { useState } from 'react'
import { Clock, MapPin, Tag } from 'react-feather'
import { formatPrice, formatTimeRange } from '../utils/helpers'

import {
  RosterItem,
  RosterSidebar,
  RosterContent,
  RosterInfo,
  RosterList,
  RosterText,
} from '../styled/Roster'
import { H3, H4 } from '../styled/Heading'

const Event = ({ event }) => {
  const {
    Evenementnaam,
    Startdatum_en_tijd,
    Einddatum_en_tijd,
    Prijs,
    Locatie,
    Omschrijving,
  } = event.node.data
  const [active, setActive] = useState(false)

  const handleActive = (e) => {
    e.stopPropagation()
    setActive(!active)
  }

  return (
    <RosterItem
      hasClick={Omschrijving.childMarkdownRemark.html}
      onClick={handleActive}
      aria-expanded={active}
      small
    >
      <RosterSidebar>
        <H4
          as="time"
          sansSerif
          dateTime={new Date(Startdatum_en_tijd).toLocaleDateString('nl-NL', {
            hour: 'numeric',
            minute: 'numeric',
          })}
        >
          {new Date(Startdatum_en_tijd).toLocaleDateString('nl-NL', {
            weekday: 'short',
            day: 'numeric',
            timeZone: 'Europe/Amsterdam',
          })}
        </H4>
      </RosterSidebar>

      <RosterContent>
        <H3 as="h4">{Evenementnaam}</H3>
        <RosterInfo>
          <RosterList>
            <li>
              <Clock size={16} />
              <time
                dateTime={new Date(Startdatum_en_tijd).toLocaleDateString(
                  'nl-NL',
                  {
                    hour: 'numeric',
                    minute: 'numeric',
                  }
                )}
              >
                {formatTimeRange(Startdatum_en_tijd, Einddatum_en_tijd)}
              </time>
            </li>
            {Prijs > 0 ? (
              <li>
                <Tag size={16} />
                {formatPrice(Prijs)}
              </li>
            ) : (
              <li>
                <Tag size={16} />
                Gratis
              </li>
            )}
            <li>
              <MapPin size={16} />
              {Locatie}
            </li>
          </RosterList>
        </RosterInfo>
        {Omschrijving.childMarkdownRemark.html && (
          <RosterText
            active={active}
            dangerouslySetInnerHTML={{
              __html: Omschrijving.childMarkdownRemark.html,
            }}
          />
        )}
      </RosterContent>
    </RosterItem>
  )
}

export default Event
