import React, { useState } from 'react'
import styled from 'styled-components'
import { H1 } from '../styled/Heading'
import { Roster } from '../styled/Roster'
import { TabsList, TabContent } from '../styled/Tabs'
import Contain from '../styled/Contain'
import Event from './Event'

const Events = ({ events }) => {
  // Set initial tab to first item in array
  const [activeTab, setActiveTab] = useState(0)

  const handleActiveTab = (index) => {
    setActiveTab(index)
  }

  // Sort events by month
  const eventsGroupedByMonth = events.reduce((accumulator, currentValue) => {
    // Complete timeStamp (eg. 2019-07-19T19:00:00+0000)
    const timeStamp = currentValue.node.data.Startdatum_en_tijd

    // Format key to date (eg. Fri Jul 19 2019 21:00:00 GMT+0200 (Central European Summer Time)
    const date = new Date(timeStamp)

    // Get month name of date (eg. juli)
    const month = date.toLocaleString('nl-NL', { month: 'long' })

    // Capitalize the first letter of the month, for clean HTML outlines (eg. Juli)
    const monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1)

    // If there is no month yet create new array
    if (!accumulator[monthCapitalized]) {
      accumulator[monthCapitalized] = []
    }

    // Push month into corresponding array
    accumulator[monthCapitalized].push(currentValue)

    return accumulator
  }, {})

  return (
    <EventsWrapper>
      <Contain size="medium">
        <H1 as="h2">Agenda</H1>
      </Contain>
      <Contain size="small">
        <TabsList role="tablist" style={{ display: 'flex' }}>
          {Object.keys(eventsGroupedByMonth).map((label, index) => (
            <li activetab={activeTab} key={`${label}-${index}`}>
              <button
                onClick={() => handleActiveTab(index)}
                type="button"
                role="tab"
                id={`tab-${label}-${index}`}
                aria-controls={`${label}-${index}`}
                aria-selected={activeTab === index}
                disabled={activeTab === index}
              >
                {label}
              </button>
            </li>
          ))}
        </TabsList>
        {Object.keys(eventsGroupedByMonth).map((month, index) => (
          <TabContent
            hidden={activeTab !== index}
            key={`${month}-${index}`}
            id={`${month}-${index}`}
          >
            <Roster key={month}>
              {eventsGroupedByMonth[month].map((event, index) => (
                <Event key={index} event={event} />
              ))}
            </Roster>
          </TabContent>
        ))}
      </Contain>
    </EventsWrapper>
  )
}

export default Events

const EventsWrapper = styled(Contain)`
  margin-top: 40px;

  @media (min-width: ${(props) => props.theme.layout.breakpoints.large}) {
    margin-top: 120px;
  }
`
