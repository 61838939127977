import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Panorama from '../components/Panorama'
import Events from '../components/Events'
import Contain from '../styled/Contain'
import { formatDate, formatPrice, formatTimeRange } from '../utils/helpers'

export default function Agenda({ data }) {
  // 1. Get all events (ascending)
  const events = data.allAirtable.edges

  // 2. Store all events with an image
  const eventsWithImage = events.filter((event) => event.node.data.Foto)

  // 3. Desctructure first event with an image
  const {
    // eslint-disable-next-line camelcase
    Startdatum_en_tijd,
    // eslint-disable-next-line camelcase
    Einddatum_en_tijd,
    Evenementnaam,
    Foto,
    // eslint-disable-next-line camelcase
    Video_url,
    Locatie,
    Prijs,
  } = eventsWithImage[0].node.data

  const image = Foto?.localFiles[0]?.childImageSharp?.gatsbyImageData || false
  // eslint-disable-next-line camelcase
  const video = Video_url || false

  return (
    <Layout>
      <Panorama
        title={Evenementnaam}
        date={formatDate(Startdatum_en_tijd)}
        location={Locatie}
        time={formatTimeRange(Startdatum_en_tijd, Einddatum_en_tijd)}
        price={formatPrice(Prijs)}
        image={image}
        video={video}
        secondary
      />
      <Contain>
        <Events events={events} />
      </Contain>
    </Layout>
  )
}

export const Head = () => <SEO title="Agenda" />

export const query = graphql`
  query Agenda($currentDate: Date!) {
    allAirtable(
      filter: {
        table: { eq: "Agenda" }
        data: { Startdatum_en_tijd: { gte: $currentDate } }
      }
      sort: { fields: data___Startdatum_en_tijd, order: ASC }
    ) {
      edges {
        node {
          data {
            Evenementnaam
            Startdatum_en_tijd
            Einddatum_en_tijd
            Prijs
            Locatie
            Omschrijving {
              childMarkdownRemark {
                html
              }
            }
            Foto {
              localFiles {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
            Video_url
          }
        }
      }
    }
  }
`

Agenda.propTypes = {
  data: PropTypes.shape({
    allAirtable: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            data: PropTypes.shape({
              Evenementnaam: PropTypes.string.isRequired,
              Startdatum_en_tijd: PropTypes.string.isRequired,
              Einddatum_en_tijd: PropTypes.string.isRequired,
              Prijs: PropTypes.number.isRequired,
              Locatie: PropTypes.string.isRequired,
              Foto: PropTypes.object,
              Video_url: PropTypes.string,
              Toon_op_homepage: PropTypes.bool,
            }).isRequired,
          }).isRequired,
        })
      ),
    }),
  }),
}
